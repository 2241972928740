body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(56, 40, 34);
  background-color: rgb(250, 248, 245);
}

input[type='search'] {
  padding: 0.2em;
  font-size: 0.8em;
  min-width: 22em;
  width: 100%;
}

@media (max-width: 350px) {
  input[type='search'] {
    margin-left: -26px;
    margin-right: -26px;
  }
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: searchfield-decoration;
  display: block;
  -webkit-flex: none;
  -webkit-align-self: flex-start;
  margin: auto 0;
}

a {
  color: #000;
  text-decoration: underline;
}
